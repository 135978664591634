<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-screen">
				<el-form size="small">
					<el-form-item :label="$t('teacher.formItem.student')">
						<el-input v-model="screenForm.student_name" clearable :placeholder="$t('teacher.placeholder.studentName')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.account')">
						<el-input v-model="screenForm.user_name" clearable :placeholder="$t('teacher.placeholder.account')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.shopName')">
						<el-input v-model="screenForm.store_name" clearable :placeholder="$t('teacher.placeholder.shopName')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.belongClass')">
						<el-select v-model="screenForm.class_id" class="w_all" clearable>
							<el-option v-for="(item,index) in classOption" :key="index" :label="item.name"
								:value="item.class_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.statisticalTime')">
						<el-date-picker v-model="datetime" class="w_all" type="daterange" value-format="yyyy-MM-dd"
							range-separator="-" :start-placeholder="$t('teacher.placeholder.startDate')" :end-placeholder="$t('teacher.placeholder.endDate')"
							:picker-options="pickerOptions">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearch"><i class="el-icon-search"></i></el-button>
						<span class="search-clear" @click="handleClearSearch"><i class="el-icon-brush"></i>{{$t('teacher.actions.clear')}}</span>
					</el-form-item>
				</el-form>
			</div>
			<div class="t-page-table">
				<el-table :data="pageList" size="small" @sort-change="handleSortPageChange">
					<el-table-column :label="$t('teacher.tableHead.studentAccount')">
						<template slot-scope="scope">
							<div class="user-info">
								<h1>{{$t('teacher.base.student')+':'}}{{scope.row.real_name}}</h1>
								<p>{{$t('teacher.base.account')+':'}}{{scope.row.user_name}}</p>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.shopName')" prop="store_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.belongClass')" prop="class_name"></el-table-column>
					<el-table-column align="center" :label="$t('teacher.tableHead.liveCount')">
						<template slot-scope="scope">
							<router-link class="item-link"
								:to="{name:'teacherLiveField',query:{id:scope.row.store_id,begin_time:datetime[0],end_time:datetime[1]}}">{{scope.row.live_count}}
							</router-link>
						</template>
					</el-table-column>
					<el-table-column align="center" :label="$t('teacher.tableHead.liveTimes')" prop="live_times" sortable="custom">
					</el-table-column>
					<el-table-column align="center" :label="$t('teacher.tableHead.dealOrder')" prop="completed_orders" sortable="custom">
						<template slot-scope="scope">
							<router-link class="item-link"
								:to="{name: 'teacherOrder',query:{id:scope.row.store_id,is_live: 1,begin_time:datetime?datetime[0]:scope.row.begin_time,end_time:datetime?datetime[1]:scope.row.end_time}}">{{scope.row.completed_orders}}
							</router-link>
						</template>
					</el-table-column>
					<el-table-column align="center" :label="$t('teacher.tableHead.dealAmount')" prop="trans_amount" sortable="custom"></el-table-column>
					<el-table-column align="center" :label="$t('teacher.tableHead.statisticalDate')" min-width="120px">
						<template slot-scope="scope">
							{{scope.row.begin_time+' - '+scope.row.end_time}}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="t-page-bottom">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pageCurr" :page-size="pageSize" layout="total, prev, pager, next"
					:prev-text="$t('teacher.pagination.prevText')" :next-text="$t('teacher.pagination.nextText')" :total="pageTotal">
				</el-pagination>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchClass, getLiveList } from '@/api/teacher';
	const defaultForm = {
		student_name: '',
		user_name: '',
		store_name: '',
		class_id: '',
	}
	export default {
		data() {
			return {
				screenForm: Object.assign({}, defaultForm),
				datetime: '',
				pickerOptions: {
					disabledDate(time) {
						const date = new Date()
						const start_time = new Date(date.getFullYear() - 3, date.getMonth(), date.getDate()-1)
						const end_time = new Date(date.getFullYear(), date.getMonth(), date.getDate()-1)
						return time.getTime() < start_time.getTime() || time.getTime() > end_time.getTime()
					},
				},
				classOption: [],
				pageList: [],
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
				sortSign: '',
				sortType: '',
			}
		},
		created() {
			this.getClassOption();
			this.getPageList();
		},
		methods: {
			getClassOption() {
				let params = {
					page_index: 0,
					page_size: 1000
				}
				fetchClass(params).then(response => {
					this.classOption = response.data.list;
				})
			},
			getPageList() {
				let params = {
					...this.screenForm,
					sort_sign: this.sortSign,
					sort_type: this.sortType,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize
				}
				if (this.datetime) {
					params.begin_time = this.datetime[0];
					params.end_time = this.datetime[1];
				}
				getLiveList(params).then(response => {
					this.pageList = response.data.list;
					this.pageTotal = parseInt(response.data.count);
				})
			},
			handleSearch() {
				this.pageCurr = 1;
				this.getPageList();
			},
			handleClearSearch() {
				this.screenForm = Object.assign({}, defaultForm)
				this.datetime = '';
				this.pageCurr = 1;
				this.getPageList();
			},
			handleSizeChange(val) {
				this.pageSize = val;
			},
			handleCurrentChange(val) {
				this.pageCurr = val;
				this.getPageList();
			},
			handleSortPageChange(column) {
				switch (column.prop) {
					case 'live_times':
						this.sortSign = 1;
						break;
					case 'completed_orders':
						this.sortSign = 2;
						break;
					case 'trans_amount':
						this.sortSign = 3;
						break;
				}
				if (column.order == 'ascending') {
					this.sortType = 'asc'
				} else {
					this.sortType = 'desc'
				}
				this.pageCurr = 1;
				this.getPageList()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;

		.t-page-title {
			padding-bottom: 20px;

			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.t-page-screen {

			// padding-top: 18px;
			// border-top: 1px solid #EBEEF5;
			::v-deep .el-form {
				display: flex;
				flex-wrap: wrap;
				.el-form-item {
					width: 320px;
					flex-shrink: 0;
					display: flex;
					padding: 0 9px;
					margin-right: 0;
					margin-bottom: 16px;
					box-sizing: border-box;
					.el-form-item__label {
						min-width: 68px;
					}
					.el-form-item__content {
						flex: 1;
						width: 0;
					}
				}
			}
			.w100 {
				width: 100px;
			}
			.w_all {
				width: 100%;
			}

			.search-clear {
				cursor: pointer;
				margin-left: 12px;

				i {
					margin-right: 4px;
				}
			}
		}

		.t-page-table {
			::v-deep .el-table {
				th.el-table__cell {
					background-color: $--seller-thead-background-1;
				}
			}

			.user-info {
				h1 {
					font-size: 14px;
					line-height: 20px;
				}

				p {
					font-size: 12px;
					line-height: 16px;
					color: #999999;
				}
			}

			.item-link {
				color: $--seller-primary;
			}

			.item-link:hover {
				text-decoration: underline;
			}
		}

		.t-page-bottom {
			padding: 15px 0;
			text-align: center;
		}
	}
</style>
